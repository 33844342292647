import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import AppRouter from "./components/routing/AppRouter";
import Store, { Context } from "./components/context/Store";

function App() {
    return (
        <Store>
            <AppRouter />
        </Store>
    );
}

export default App;
