import axios from "axios";

// development server
// export const femmeConfig = axios.create({
//     baseURL: "https://developers-api.thefemme.org/api/v1/",
// });

// Live server
export const femmeConfig = axios.create({
    baseURL: "https://api.thefemme.org/api/v1/",
});
