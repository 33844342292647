import React, { useRef } from "react";
import Spotlight from "./Spotlight";
import AboutUs from "./AboutUs";
import OverView from "./OverView";
import Register from "./Register";
import Footer from "./Footer";

function EmpowerPages() {
    const registerRef = useRef();

    const scrollToRegister = () => {
        registerRef.current.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <div>
            <Spotlight onApplyNowClick={scrollToRegister} />
            <AboutUs onApplyNowClick={scrollToRegister} />
            <OverView />
            <Register scrollToRegister={registerRef} />
            <Footer />
        </div>
    );
}
export default EmpowerPages;
