import React from "react";
import { Link, Outlet } from "react-router-dom";
import styled from "styled-components";
import "../../assets/css/style.css";

function AppRouter() {
    return (
        <>
            <Div>
                <section id="spotlight">
                    <section className="card">
                        <h1>
                            <a href="/" className="thumb">
                                <Image
                                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/femme/logo.png"
                                    alt="Icon"
                                />
                            </a>
                        </h1>
                        <img
                            src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/femme/spotlight.png"
                            alt="image"
                        />
                        <p>
                            Here's to strong women,
                            <br />
                            May we know them, May we be them,
                            <br />
                            May we raise them!
                        </p>

                        <span className="button">
                            We are brewing something awesome!
                        </span>
                        <p className="initiative">
                            An initiative by{" "}
                            <a target="_blank" href="http://talrop.com/">
                                Talrop
                            </a>
                        </p>
                        <Link className="links" to="/members/register/">
                            Register
                        </Link>
                    </section>
                </section>
            </Div>
            <Outlet />
        </>
    );
}

export default AppRouter;
const Div = styled.div`
    height: 100vh;
    & .links {
        background: #f5e9f7;
        color: #9e51af;
        font-size: 19px;
        padding: 9px 30px;
        font-weight: bold;
        border-radius: 38px;
    }
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;
