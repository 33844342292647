import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CountrySelector from "../includes/CountrySelector";
import ButtonLoader from "../includes/ButtonLoader";
// import OtpModal from "../modals/OtpModal";
import SuccessModal from "../modals/SuccessModal";
import { Context } from "../context/Store";
import { femmeConfig } from "../../axiosConfig";
// import ReCAPTCHA from "react-google-recaptcha";

const FemmeForm = () => {
  // email validation
  const [email, setEmail] = useState("");
  const [isEmailError, setEmailError] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState("This field is required");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("This field is required");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  // const [otpVal, setOtpVal] = useState("");
  const [organization, setOrganization] = useState("");
  const [category, setCategory] = useState("");

  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [isSuccess, setSuccess] = useState(false);
  // const [isModal, setModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryselector, setCountryselector] = useState(false);
  const { state, dispatch } = useContext(Context);
  const [numErrMsg, setNumErrMsg] = useState("");

  const [selected, setSelected] = useState({
    country_code: "IND",
    flag: "https://d38z36hay4oql7.cloudfront.net/media/countries/flags/india.png",
    name: "India",
    phone_code: "+91",
    phone_number_length: 10,
    web_code: "IN",
  });

  // const recaptchaRef = useRef();

  // email validation
  const onEmailChange = (e) => {
    let str = e.target.value;

    setEmail(e.target.value);
    if (/\S+@\S+\.\S+/.test(e.target.value) && str.includes(".com")) {
      setEmailError(false);
      setEmailErrMsg("");
    } else {
      setEmailError(true);
      setEmailErrMsg("Enter a valid email");
    }
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };
  const handleShow = () => {
    setCountryselector((prevValue) => !prevValue);
  };
  const onSelectHandler = (selected) => {
    setSelectedCountry(selected);
  };

  // phone number validation
  const onPhoneChange = (e) => {
    const re = /^[0-9\b]+$/;
    let value = e.target.value;
    if (value === "" || re.test(value)) {
      setPhone(value);
      if (value.length !== state.selected_country.phone_number_length) {
        setPhoneError(true);
        setPhoneErrorMsg("Invalid Phone number");
      } else if (e.target.value === "") {
        setPhoneError(true);
        setPhoneErrorMsg("Invalid number");
      } else {
        setPhoneError(false);
        setPhoneErrorMsg("");
      }
    }
  };
  //  name validation
  const onNameChange = (e) => {
    let value = e.target.value.replace(/[0-9]+/g, "");
    setName(value);
  };

  // form submission
  const submitForm =  () => {

    setLoading(true);

    if (
      name &&
      phone &&
      email &&
      category &&
      organization &&
      isError === false &&
      isEmailError === false
    ) {
      // const token = await recaptchaRef.current.executeAsync();

      const formData = new FormData();
      formData.append("name", name);
      formData.append("country", selected.web_code);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("organization", organization);
      formData.append("type", category);
      // formData.append("g-recaptcha-response", token);

      femmeConfig
        .post("web/member/registration/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const { StatusCode, data } = response.data;
          if (StatusCode === 6000) {
            setSuccess(true)
            // setModal(true);

            setLoading(false);
            setError(false);
            dispatch({
              type: "UPDATE_USER_DATA",
              user_data: {
                phone: phone,
                name: name,
                country: selected.web_code,
              },
            });
          } else {
            setError(true);
            setLoading(false);
            setNumErrMsg(response.data.data.message);
          }
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
        });
    } else {
      setError(true);
      setEmailError(true);
      setPhoneError(true);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setError(false);
      setEmailError(false);
      setPhoneError(false);
    }, 3000);
  }, [isError, phoneError]);

  // const onResetCaptcha = () => {
  //   recaptchaRef.current.reset();
  // };
  return (
    <>
      <FormSection>
        <CountrySelector
          show={countryselector}
          handleClick={handleShow}
          onSelectHandler={onSelectHandler}
          selectedCountry={selectedCountry}
          selected={selected}
          setSelected={setSelected}
        />
        {/* {isModal && (
          <OtpModal
            isModal={isModal}
            setModal={setModal}
            phone={phone}
            country={selected.web_code}
            phone_code={selected.phone_code}
            selected={selected}
            setSuccess={setSuccess}
            // otpVal={otpVal}
            // setOtpVal={setOtpVal}
            isError={isError}
            setError={setError}
          />
        )} */}
        {isSuccess && (
          <SuccessModal isSuccess={isSuccess} setSuccess={setSuccess} />
        )}
        <Bottom>
          <form className="wrapper">
            <Left>
              <h4>Register Now</h4>
            </Left>
            <Subtitle>
              Register and be part of the community of women who make change!
            </Subtitle>
            <InputContainer>
              <Title>Name*</Title>
              <InputFields>
                <input
                  id="only-text"
                  type="text"
                  placeholder="Enter your name"
                  onChange={onNameChange}
                  value={name}
                  maxLength={200}
                />
              </InputFields>
              {isError && name === "" ? (
                <ErrorMessage>This field is required</ErrorMessage>
              ) : (
                ""
              )}
            </InputContainer>
            <InputContainer>
              <Title>Phone Number*</Title>
              <InputFields>
                <LeftFlag onClick={() => setCountryselector(!countryselector)}>
                  <ImageCont>
                    <img
                      src={
                        state.selected_country.flag
                          ? state.selected_country.flag
                          : selected.flag
                      }
                      alt="flag"
                    />
                  </ImageCont>
                  <DownArrowBox>
                    <img
                      src={require("../../assets/images/down-arrow.png")}
                      alt="down-arrow"
                    />
                  </DownArrowBox>
                  <Code>
                    {state.selected_country.phone_code
                      ? state.selected_country.phone_code
                      : selected.phone_code}
                  </Code>
                </LeftFlag>
                <input
                  className="phones"
                  type="number"
                  placeholder="Enter your phone"
                  value={phone}
                  onChange={onPhoneChange}
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                />
              </InputFields>

              {phoneError && phone === "" ? (
                <ErrorMessage>This field is required</ErrorMessage>
              ) : (
                phoneError && <ErrorMessage>{phoneErrorMsg}</ErrorMessage>
              )}
            </InputContainer>
            <InputContainer>
              <Title>Email Address*</Title>
              <InputFields>
                <input
                  type="email"
                  placeholder="Enter your email"
                  onChange={onEmailChange}
                  value={email}
                />
              </InputFields>
              {isEmailError ? <ErrorMessage>{emailErrMsg}</ErrorMessage> : ""}
            </InputContainer>
            <InputContainer>
              <Title>Category*</Title>
              <InputFields className="gendercontainer">
                <CoverCont>
                  <input
                    type="radio"
                    placeholder="Enter your name"
                    className="category"
                    value="1"
                    id="student"
                    name="category"
                    onChange={(e) => handleCategory(e)}
                  />
                  <label htmlFor="student">Student</label>
                </CoverCont>
                <CoverCont>
                  <input
                    type="radio"
                    placeholder="Enter your name"
                    className="category"
                    value="2"
                    id="professional"
                    name="category"
                    onChange={(e) => handleCategory(e)}
                  />
                  <label htmlFor="professional">Professional</label>
                </CoverCont>
              </InputFields>
              {isError && category === "" ? (
                <ErrorMessage>This field is required</ErrorMessage>
              ) : (
                ""
              )}
            </InputContainer>
            <InputContainer>
              <Title>Name of Organization/Campus*</Title>
              <InputFields>
                <input
                  type="text"
                  placeholder="Enter name of Organization/Campus"
                  onChange={(e) => setOrganization(e.target.value)}
                  value={organization}
                />
              </InputFields>

              {isError && organization === "" ? (
                <ErrorMessage>This field is required</ErrorMessage>
              ) : (
                ""
              )}
              {isError && (
                <ErrorMessage className="error-msg">{numErrMsg}</ErrorMessage>
              )}
            </InputContainer>
          </form>
          {/* <ReCAPTCHA
            className="ReCAPTCHA"
            ref={recaptchaRef}
            sitekey="6Ld08tEjAAAAAOfhtkXWZvC0Ep94RaARKhGybwqU"
            size="invisible"
            badge="bottomleft"
            onChange={onResetCaptcha}
          /> */}

          <ButtonSect>
            {isLoading ? (
              <Loader>
                {" "}
                <ButtonLoader />{" "}
              </Loader>
            ) : (
              <input type="submit" value="Continue" onClick={submitForm} />
            )}
          </ButtonSect>
        </Bottom>
      </FormSection>
    </>
  );
};

export default FemmeForm;
const FormSection = styled.section`
  padding: 50px 0 50px 0;
  background-color: #f9f9f9;
  & .wrapper {
    padding: 0 50px;
    @media all and (max-width: 980px) {
      padding: 0;
    }
  }
  @media all and (max-width: 480px) {
    padding: 40px 0 40px;
  }
  @media all and (max-width: 1280px) {
  }
`;
const Loader = styled.div`
  width: 200px;
  height: 45px;
  background: #9f51b0;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

const CoverCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    font-size: 14px;
    margin-right: 15px;
    cursor: pointer;
    @media (max-width: 420px) {
      font-size: 12px;
      margin-right: 10px;
    }
  }
  input {
    margin-right: 5px;
    font-size: 15px;
    cursor: pointer;
  }
`;
const Code = styled.div`
  font-size: 15px;
  color: #000;
  margin-left: 8px;
  @media (max-width: 640px) {
  }
`;

const Left = styled.span`
  margin-bottom: 10px;
  h4 {
    font-size: 22px;
  }
`;

const Subtitle = styled.p`
  color: #000;
  @media all and (max-width: 1280px) {
    width: 50%;
    text-align: center;
  }
  @media all and (max-width: 980px) {
    width: 42%;
  }
  @media all and (max-width: 750px) {
    width: 50%;
  }
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;

const Bottom = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
const InputContainer = styled.div`
  width: 48%;
  margin-top: 30px;
  position: relative;
  cursor: pointer;
  @media (max-width: 640px) {
    width: 100%;
  }
  p.error-msg {
    position: absolute;
    bottom: -25px;
  }
`;
const Title = styled.h4`
  font-size: 16px;
  color: #6b6b6b;
  margin-bottom: 5px;
`;
const InputFields = styled.div`
  color: #000;
  width: 100%;
  height: 48px;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;

  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &.gendercontainer {
    border: 1px solid #e7e7e7;
    padding-left: 8px;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 11px;
    font-size: 16px;
    color: #000;
    &::placeholder {
      font-size: 16px;
      color: #868282;
    }
    &.category {
      width: 20px;
      height: 20px;
      @media (max-width: 420px) {
        width: 15px;
        height: 15px;
      }
      & .phones {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
  }
`;
const ButtonSect = styled.div`
  width: 100%;
  margin-top: 70px;
  padding-bottom: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  input {
    width: 200px;
    height: 45px;
    background: #9f51b0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    font-size: 18px;
  }

  @media all and (max-width: 480px) {
    padding-bottom: 25px;
    margin-top: 40px;
  }
`;
const LeftFlag = styled.div`
  display: flex;
  margin-left: 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  @media (max-width: 1280px) {
    width: 20%;
  }
  @media (max-width: 1080px) {
    width: 26%;
  }
  @media (max-width: 768px) {
    width: 29%;
  }
  @media (max-width: 640px) {
    width: 17%;
  }
  @media (max-width: 480px) {
    width: 20%;
  }
  @media (max-width: 420px) {
    width: 22%;
  }
  @media (max-width: 380px) {
    width: 26%;
  }
`;
const ImageCont = styled.div`
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;

  @media (max-width: 640px) {
    width: 28px;
    height: 28px;
  }
  img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
  }
`;
const DownArrowBox = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 5px;
  img {
    width: 100%;
    height: 100%;
  }
`;
const ErrorMessage = styled.p`
  color: red;
  position: absolute;
  right: 0;
  bottom: -25px;
  font-size: 12px;
`;
