import React from "react";
import styled from "styled-components";
import { data } from "../../../../src/components/screens/empowerher/data/data";

function AboutUs({ onApplyNowClick }) {
    return (
        <ProgramSection>
            <BackgroundTop>
                <ImgContainerTop>
                    <Img
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/background-1.png"
                        alt="image"
                    />
                </ImgContainerTop>
            </BackgroundTop>
            <BackgroundBottom>
                <ImgContainerBottom>
                    <Img
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/background-2.png"
                        alt="image"
                    />
                </ImgContainerBottom>
            </BackgroundBottom>
            <Wrapper className="wrapper">
                <ProgramContainer>
                    <Span>EmpowerHer</Span>
                    <Heading>Program Overview</Heading>
                    <Paragraph>
                        Unlock your leadership potential and shape the future
                        with EmpowerHer. Join us on a transformative journey of
                        personal growth, innovation, and social impact.
                    </Paragraph>
                    <Cards>
                        {data.map((item, id) => (
                            <Card key={id}>
                                <ImageContainer>
                                    <Img src={item.src} />
                                </ImageContainer>
                                <SubHeading>{item.name}</SubHeading>
                                <Description>{item.discription}</Description>
                                <ApplyButton onClick={onApplyNowClick}>
                                    <Apply>{item.button}</Apply>
                                </ApplyButton>
                            </Card>
                        ))}
                    </Cards>
                </ProgramContainer>
            </Wrapper>
        </ProgramSection>
    );
}
export default AboutUs;

const ProgramSection = styled.section`
    padding: 70px 0;
    position: relative;
    @media all and (max-width: 480px) {
        padding: 60px 0;
    }
`;
const Wrapper = styled.section``;
const ProgramContainer = styled.div``;
const Span = styled.span`
    color: #601b6e;
    font-family: "product_sansbold";
    font-size: 25px;
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;
const Heading = styled.h1`
    color: #222;
    font-family: "product_sansbold";
    font-size: 40px;
    margin: 20px 0;
    @media all and (max-width: 768px) {
        font-size: 34px;
        margin: 15px 0;
    }
    @media all and (max-width: 480px) {
        font-size: 28px;
        margin: 10px 0;
    }
`;

const Paragraph = styled.p`
    color: #222;
    font-size: 22px;
    width: 67%;
    line-height: 29px;
    margin-bottom: 40px;
    font-family: "product_sansregular";
    @media all and (max-width: 1280px) {
        font-size: 19px;
        width: 72%;
    }
    @media all and (max-width: 1080px) {
        width: 78%;
    }
    @media all and (max-width: 768px) {
        font-size: 17px;
        width: 93%;
        line-height: 25px;
    }
`;
const Cards = styled.ul`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const Card = styled.li`
    width: 32%;
    border: 1px solid #ccc;
    padding: 45px 20px;
    border-radius: 10px;
    text-align: center;
    /* z-index: -1; */
    background: #fff;
    &:hover {
        color: #222;
    }
    @media all and (max-width: 980px) {
        width: 48%;
        margin-bottom: 20px;
        &:last-child {
            margin: 0 auto;
        }
    }
    @media all and (max-width: 768px) {
        padding: 30px 15px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const ImageContainer = styled.div`
    width: 13%;
    margin: 0 auto;
    margin-bottom: 20px;
`;
const Img = styled.img`
    display: block;
    width: 100%;
`;
const SubHeading = styled.h3`
    color: #222;
    font-family: "product_sansbold";
    font-size: 25px;
    text-align: center;
    width: 95%;
    @media all and (max-width: 1280px) {
        font-size: 21px;
    }
    @media all and (max-width: 1080px) {
        font-size: 19px;
    }
    @media all and (max-width: 640px) {
        font-size: 22px;
    }
`;
const Description = styled.p`
    color: #222;
    font-size: 20px;
    font-family: "product_sansregular";
    line-height: 30px;
    margin: 20px 0;
    @media all and (max-width: 1380px) {
        font-size: 19px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
    @media all and (max-width: 640px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 17px;
    }
`;
const ApplyButton = styled.button`
    background-color: #601b6e;
    border-radius: 8px;
    padding: 13px 20px;
    font-family: "product_sansregular";
    cursor: pointer;

    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        padding: 10px 20px;
    }
`;
const Apply = styled.a`
    font-size: 20px;
    color: #ffffff;
`;
const BackgroundTop = styled.div`
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0%;
    @media all and (max-width: 768px) {
        top: 8%;
    }
    @media all and (max-width: 480px) {
        top: 10%;
    }
`;
const BackgroundBottom = styled.div`
    position: absolute;
    z-index: -1;
    bottom: 0%;
    left: 0;
`;
const ImgContainerTop = styled.div`
    width: 100%;
`;
const ImgContainerBottom = styled.div`
    width: 100%;
`;
