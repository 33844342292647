import React, { Suspense, lazy, useEffect, useState } from "react";
import { Switch, Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";
import SuccessModal from "../modals/SuccessModal";
import FemmeForm from "../screens/FemmeForm";
import LandingPage from "../screens/LandingPage";
import EmpowerPages from "../screens/empowerher/EmpowerPages";


function AppRouter() {
    return (
        <>
            <Suspense>
                <Routes>
                    <Route exact path="/" element={<LandingPage />} />

                    <Route path="/success" element={<SuccessModal />} />
                    {/* <Route path="/" element={<Navigate replace to="/members/register/" />} /> */}
                    <Route
                        exact
                        path="/members/register/"
                        element={<FemmeForm />}
                    />
                    <Route exact path="/empowerher" element={<EmpowerPages />} />
                </Routes>
            </Suspense>
        </>
    );
}

export default AppRouter;

const BubbleCard = styled.div`
    position: fixed;
    background: #fff;
    box-shadow: rgb(0 18 46 / 16%) 0px 8px 36px 0px;
    bottom: 133px;
    right: 26px;
    cursor: pointer;
    border-radius: 11px;
    pointer-events: auto;
    z-index: 9999;
    left: auto;
    padding: 18px 25px;
    display: flex;
    align-items: center;
    gap: 20px;
    span.shape {
        width: 0px;
        height: 0px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 13px solid rgb(255, 255, 255);
        position: absolute;
        right: 17px;
        bottom: -8px;
    }
    @media (max-width: 768px) {
        bottom: 112px;
        right: 17px;
    }
    @media (max-width: 480px) {
        bottom: 109px;
        right: 13px;
    }
`;
const Emoji = styled.span`
    font-size: 29px;
`;
const Para = styled.p`
    font-family: "gordita_regular";
    font-size: 14px;
    color: #2d2d2d;
    span {
        font-family: "gordita_medium";
        text-transform: uppercase;
    }
`;
const Close = styled.img`
    position: absolute;
    right: 11px;
    top: 13px;
    width: 9px;
    display: block;
    cursor: pointer;
`;
