import React from "react";
//packages
import styled from "styled-components";
// import {useRef} from 'react';

function Spotlight({ onApplyNowClick }) {
    return (
        <SpotlightSection>
            <Wrapper className="wrapper">
                <SpotlightContainer>
                    <TopContainer>
                        <h1>
                            <a href="">
                                <ImgContainer>
                                    <Img
                                        src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/femme/logo.png"
                                        alt="logo"
                                    />
                                </ImgContainer>
                            </a>
                        </h1>
                    </TopContainer>
                    <BottomContainer>
                        <Left>
                            <Heading>
                                <Span>EmpowerHer</Span>
                                <br />
                                Shaping Tomorrow’s Leaders Today
                            </Heading>
                            <ApplyButton onClick={onApplyNowClick}>
                                <Apply>Apply Now</Apply>
                            </ApplyButton>
                        </Left>
                        <Right>
                            <Imgspotlight>
                                <Img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/spotlight-img.svg"
                                    alt="logo"
                                />
                            </Imgspotlight>
                        </Right>
                    </BottomContainer>
                </SpotlightContainer>
                <Key>
                    <ImgKey>
                        <Img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/mouse.svg"
                            alt="logo"
                        />
                    </ImgKey>
                </Key>
            </Wrapper>
        </SpotlightSection>
    );
}
export default Spotlight;

const SpotlightSection = styled.section`
    background-color: #e0adeb;
    padding: 20px 0px 100px 0px;
    @media all and (max-width: 1080px) {
        padding: 20px 0px 50px 0px;
    }
`;
const Wrapper = styled.section`
    width: 90%;
    margin: 0 auto;
`;
const SpotlightContainer = styled.div``;
const TopContainer = styled.div`
    margin-bottom: 30px;
`;
const ImgContainer = styled.div`
    width: 15%;
    @media all and (max-width: 768px) {
        width: 30%;
    }
    @media all and (max-width: 480px) {
        width: 38%;
    }
    @media all and (max-width: 360px) {
        width: 46%;
    }
`;
const Img = styled.img`
    width: 100%;
    display: block;
`;
const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media all and (max-width: 641px) {
        display: block;
    }
`;
const Left = styled.div`
    width: 60%;
    @media all and (max-width: 641px) {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
`;
const Heading = styled.h1`
    font-size: 62px;
    width: 85%;
    line-height: 5rem;
    color: #601b6e;
    font-family: "product_sansbold";
    margin-bottom: 30px;
    @media all and (max-width: 1380px) {
        font-size: 70px;
        width: 95%;
    }
    @media all and (max-width: 1280px) {
        font-size: 60px;
    }
    @media all and (max-width: 1080px) {
        font-size: 57px;
        line-height: 4rem;

        width: 100%;
    }
    @media all and (max-width: 980px) {
        font-size: 44px;
        line-height: 3.6rem;
        width: 100%;
    }
    @media all and (max-width: 768px) {
        font-size: 36px;
        line-height: 3.2rem;
    }
    @media all and (max-width: 641px) {
        font-size: 42px;
        line-height: 3.4rem;
        width: 96%;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 25px;
        font-size: 32px;
        line-height: 3rem;
    }
    @media all and (max-width: 360px) {
        font-size: 29px;
        line-height: 2.6rem;
    }
`;

const Span = styled.span`
    color: #fff;
`;
const ApplyButton = styled.button`
    background-color: #601b6e;
    border-radius: 8px;
    padding: 15px 20px;
    cursor: pointer;
    font-family: "product_sansregular";
    @media all and (max-width: 1080px) {
        font-size: 17px;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
        padding: 12px 15px 12px 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 15px;
        padding: 9px 12px;
    }
`;
const Apply = styled.a`
    font-size: 20px;
    color: #ffffff;
`;
const Right = styled.div`
    width: 40%;
    display: flex;
    justify-content: end;
    @media all and (max-width: 641px) {
        width: 100%;
        margin: 0 auto;
    }
`;
const Imgspotlight = styled.div`
    width: 95%;
    @media all and (max-width: 641px) {
        width: 60%;
        margin: 0 auto;
    }
    @media all and (max-width: 480px) {
        width: 70%;
    }
`;
const Key = styled.div``;
const ImgKey = styled.div`
    margin: 0 auto;
    text-align: center;
    width: 2%;
    @media all and (max-width: 1280px) {
        width: 3%;
    }
    @media all and (max-width: 981px) {
        width: 5%;
    }
    @media all and (max-width: 641px) {
        width: 6%;
    }
    @media all and (max-width: 360px) {
        width: 8%;
    }
`;
