import React from "react";
import styled from "styled-components";

function Footer() {
    return (
        <FooterSection>
            <Wrapper className="wrapper">
                <FooterContainer>
                    <LeftContainer>
                        <FooterImg>
                            <Img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/copyright.svg"
                                alt=""
                            />
                        </FooterImg>
                        <Femme>2024 thefemme.org • All rights reserved</Femme>
                    </LeftContainer>
                    {/* <RightContainer>
                  <ImageContainers>
                    <ImgContainer>
                        <Img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/fb.svg" 
                          alt="image" />
                    </ImgContainer>
                    <ImgContainer>
                        <Img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/twitter.svg" 
                          alt="logo" />
                    </ImgContainer>
                    <ImgContainer>
                        <Img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/instagram.svg" 
                          alt="logo" />
                    </ImgContainer>
                    <ImgContainer>
                        <Img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/linkedlin.svg"
                          alt="logo" />
                    </ImgContainer>
                  </ImageContainers>
              </RightContainer> */}
                </FooterContainer>
            </Wrapper>
        </FooterSection>
    );
}
export default Footer;

const FooterSection = styled.section`
    padding: 30px 0;
    background: #601b6e;
    @media all and (max-width: 980px) {
        padding: 25px 0;
    }
    @media all and (max-width: 640px) {
        padding: 20px 0;
    }
`;
const Wrapper = styled.section``;

const FooterContainer = styled.div`
    /* display: flex;
    justify-content: center; */
`;
const LeftContainer = styled.div`
    /* width: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 640px) {
        /* width: 75%; */
    }
`;
const FooterImg = styled.div`
    width: 2%;
    @media all and (max-width: 640px) {
        width: 4%;
    }
`;
const Img = styled.img`
    display: block;
    width: 100%;
`;
const Femme = styled.h3`
    color: #fff;
    font-size: 17px;
    margin-left: 8px;
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 10px;
    }
`;
const RightContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: end;
`;
const ImageContainers = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20%;
    @media all and (max-width: 1380px) {
        width: 24%;
    }
    @media all and (max-width: 1280px) {
        width: 26%;
    }
    @media all and (max-width: 1080px) {
        width: 28%;
    }
    @media all and (max-width: 768px) {
        width: 38%;
    }
`;
const ImgContainer = styled.div`
    width: 18%;
`;
