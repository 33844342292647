import item1 from "../../../../assets/images/abstract-1.svg";
import item2 from "../../../../assets/images/abstract-2.svg";
import item3 from "../../../../assets/images/abstract-3.svg";

export const data = [
    {
        id: 1,
        src: item1,
        name: "Selection for Top 10 Final-Year Female Students",
        discription:
            "Our selection process focuses on interviews that assess motivation, leadership potential, and technical interest among final-year female students. The goal is to identify the top 10 candidates with outstanding leadership potential.",
        button: "Apply Now",
    },
    {
        id: 2,
        src: item3,
        name: "Year-Long Plan for EmpowerHer Program",
        discription:
            "Our year-long program unfolds in four phases: Foundation, Innovator, Catalyst, and Champion. These stages are designed to progressively focus on various aspects of personal and professional development, aiming to cultivate the leaders of tomorrow.",
        button: "Apply Now",
    },
    {
        id: 3,
        src: item2,
        name: "Capstone Project and Leadership Conference",
        discription:
            "In the Champion phase, participants undertake a capstone project and join a leadership conference to boost their leadership abilities and readiness for professional careers.",
        button: "Apply Now",
    },
];
