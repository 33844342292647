const reducers = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.user_data };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            return {
                ...state,
                user_data: user_data,
            };
        case "UPDATE_COUNTRY_DATA":
            const selected_country = {
                ...state.selected_country,
                ...action.selected_country,
            };
            localStorage.setItem("selected_country", JSON.stringify(selected_country));
            return {
                ...state,
                selected_country: selected_country,
            };
    }
};
export default reducers;
