import React, { useContext, useEffect, useRef, useState } from "react";
//packages
import styled from "styled-components";
import ButtonLoader from "../../includes/ButtonLoader";
import { femmeConfig } from "../../../axiosConfig";
import { Context } from "../../context/Store";
import SuccessModal from "../../modals/SuccessModal";

function Register({ scrollToRegister }) {
    // email validation
    const [email, setEmail] = useState("");
    const [isEmailError, setEmailError] = useState(false);
    const [emailErrMsg, setEmailErrMsg] = useState("This field is required");
    const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorMsg, setPhoneErrorMsg] = useState(
        "This field is required"
    );
    const [cource, setCource] = useState("");

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    // const [otpVal, setOtpVal] = useState("");
    const [organization, setOrganization] = useState("");

    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [isSuccess, setSuccess] = useState(false);

    const { state, dispatch } = useContext(Context);
    const [numErrMsg, setNumErrMsg] = useState("");

    const [selected, setSelected] = useState({
        country_code: "IND",
        flag: "https://d38z36hay4oql7.cloudfront.net/media/countries/flags/india.png",
        name: "India",
        phone_code: "+91",
        phone_number_length: 10,
        web_code: "IN",
    });
    console.log();

    // email validation
    const onEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(false);
        setEmailErrMsg("");
    };

    // phone number validation
    const onPhoneChange = (e) => {
        setPhoneError(false);
        setPhoneErrorMsg("");
        setPhone(e.target.value);
    };
    //  name validation
    const onNameChange = (e) => {
        let value = e.target.value.replace(/[0-9]+/g, "");
        setName(value);
    };

    // form submission
    const submitForm = () => {
        setLoading(true);
        if (/\S+@\S+\.\S+/.test(email) && email.includes(".com")) {
            setEmailError(false);
            setEmailErrMsg("");
        } else {
            setEmailError(true);
            setEmailErrMsg("Enter a valid email");
        }
        const re = /^[0-9\b]+$/;
        if (phone === "" || re.test(phone)) {
            if (phone.length !== selected.phone_number_length) {
                setPhoneError(true);
                setPhoneErrorMsg("Invalid Phone number");
            } else if (phone === "") {
                setPhoneError(true);
                setPhoneErrorMsg("Invalid number");
            } else {
                setPhoneError(false);
                setPhoneErrorMsg("");
            }
        }

        if (name && phone && email && cource && organization) {
            // const token = await recaptchaRef.current.executeAsync();

            const formData = new FormData();
            formData.append("name", name);
            formData.append("country", selected.web_code);
            formData.append("phone", phone);
            formData.append("email", email);
            formData.append("organization", organization);
            formData.append("course", cource);
            // formData.append("g-recaptcha-response", token);

            femmeConfig
                .post("web/member/empowerher-registration/", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setSuccess(true);
                        setEmailError(false);
                        setPhoneError(false);
                        setLoading(false);
                        setError(false);
                        setEmail("");
                        setCource("");
                        setPhone("");
                        setName("");
                        setOrganization("");
                    } else {
                        setError(true);
                        setLoading(false);
                        setNumErrMsg(response.data.data.message);
                    }
                })
                .catch((error) => {
                    setError(true);
                    setLoading(false);
                });
        } else {
            setError(true);
            setEmailError(true);
            setPhoneError(true);
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setNumErrMsg("");
        }, 3000);
    }, [numErrMsg]);

    return (
        <RegisterSection>
            <Wrapper className="wrapper">
                {isSuccess && (
                    <SuccessModal
                        isSuccess={isSuccess}
                        setSuccess={setSuccess}
                        isverif={true}
                    />
                )}
                <RegisterContainer ref={scrollToRegister}>
                    <LeftContainer>
                        <Content>
                            <RegisterHeading>
                                Register your interest
                            </RegisterHeading>
                            <Container>
                                <ContainerBox>
                                    <Label>
                                        Full Name<Span>*</Span>
                                    </Label>
                                    <Input
                                        id="only-text"
                                        type="text"
                                        placeholder="Enter full name"
                                        onChange={onNameChange}
                                        value={name}
                                        maxLength={200}
                                    />
                                    {isError &&
                                    numErrMsg === "" &&
                                    name === "" ? (
                                        <ErrorMessage>
                                            This field is required
                                        </ErrorMessage>
                                    ) : (
                                        ""
                                    )}
                                </ContainerBox>

                                <ContainerBox>
                                    <Label>
                                        Campus Name<Span>*</Span>
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter campus name
                                        "
                                        onChange={(e) =>
                                            setOrganization(e.target.value)
                                        }
                                        value={organization}
                                    />
                                    {isError &&
                                    numErrMsg === "" &&
                                    organization === "" ? (
                                        <ErrorMessage>
                                            This field is required
                                        </ErrorMessage>
                                    ) : (
                                        ""
                                    )}
                                </ContainerBox>
                                <ContainerBox>
                                    <Label>
                                        Course<Span>*</Span>
                                    </Label>
                                    <Input
                                        id="name"
                                        type="text"
                                        placeholder="Enter course"
                                        onChange={(e) =>
                                            setCource(e.target.value)
                                        }
                                        value={cource}
                                    />
                                    {isError &&
                                    numErrMsg === "" &&
                                    cource === "" ? (
                                        <ErrorMessage>
                                            This field is required
                                        </ErrorMessage>
                                    ) : (
                                        ""
                                    )}
                                </ContainerBox>
                                <ContainerBox>
                                    <Label>
                                        Phone Number<Span>*</Span>
                                    </Label>
                                    <Number>
                                        <Small>+91</Small>
                                        <NumberInput
                                            className="phones"
                                            type="tel"
                                            placeholder="000 000 0000"
                                            value={phone}
                                            maxLength={10}
                                            onChange={onPhoneChange}
                                            onKeyDown={(evt) =>
                                                evt.key === "e" &&
                                                evt.preventDefault()
                                            }
                                        />
                                    </Number>
                                    {phoneError &&
                                    numErrMsg === "" &&
                                    phone === "" ? (
                                        <ErrorMessage className="custom">
                                            This field is required
                                        </ErrorMessage>
                                    ) : (
                                        phoneError &&
                                        numErrMsg === "" && (
                                            <ErrorMessage className="custom">
                                                {phoneErrorMsg}
                                            </ErrorMessage>
                                        )
                                    )}
                                </ContainerBox>
                                <ContainerBox>
                                    <Label>
                                        Email Address<Span>*</Span>
                                    </Label>
                                    <Input
                                        type="email"
                                        placeholder="Enter email address"
                                        onChange={onEmailChange}
                                        value={email}
                                    />
                                    {isEmailError && numErrMsg === "" ? (
                                        <ErrorMessage>
                                            {emailErrMsg}
                                        </ErrorMessage>
                                    ) : (
                                        ""
                                    )}
                                    {isError && (
                                        <ErrorMessage className="error-msg">
                                            {numErrMsg}
                                        </ErrorMessage>
                                    )}
                                </ContainerBox>
                                <ApplyButton>
                                    <Apply onClick={submitForm}>
                                        {isLoading ? (
                                            <ButtonLoader />
                                        ) : (
                                            " Apply Now"
                                        )}
                                    </Apply>
                                </ApplyButton>
                            </Container>
                        </Content>
                    </LeftContainer>
                    <RightContainer>
                        <Heading>Join the Movement</Heading>
                        <SubHeading>Apply Now </SubHeading>
                        <ImgContainer>
                            <Img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/movement.svg" />
                        </ImgContainer>
                    </RightContainer>
                </RegisterContainer>
            </Wrapper>
        </RegisterSection>
    );
}

export default Register;

const RegisterSection = styled.section`
    padding: 70px 0;
    background-color: #e0adeb;
    @media all and (max-width: 480px) {
        padding: 60px 0;
    }
`;
const Wrapper = styled.section``;
const RegisterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;
const LeftContainer = styled.div`
    width: 50%;
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const Content = styled.div`
    border: 1px solid #ccc;
    padding: 30px 25px;
    border-radius: 10px;
    background: #fff;
    width: 75%;
    @media all and (max-width: 1280px) {
        width: 85%;
    }
    @media all and (max-width: 768px) {
        margin: 0 auto;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const RegisterHeading = styled.h2`
    font-size: 33px;
    color: #601b6e;
    font-family: "product_sansbold";
    margin-bottom: 30px;
    @media all and (max-width: 1280px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 22px;
    }
`;
const Loader = styled.div`
    width: 200px;
    height: 45px;
    background: #9f51b0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    font-size: 15px;
    display: flex;
    align-items: center;
`;
const Container = styled.div``;
const ContainerBox = styled.div`
    position: relative;
`;
const Label = styled.div`
    color: #601b6e;
    font-size: 17px;
    font-family: "product_sansregular";
    margin-bottom: 10px;
    @media all and (max-width: 1080px) {
        font-size: 16px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const Span = styled.span`
    color: red;
`;
const Input = styled.input`
    border: 1px solid #ccc;
    padding: 15px 17px 15px;
    font-size: 16px;
    border-radius: 9px;
    width: 100%;
    margin-bottom: 20px;
    color: #222;
    @media all and (max-width: 1280px) {
        margin-bottom: 15px;
    }
    @media all and (max-width: 1080px) {
        font-size: 15px;
    }
    @media all and (max-width: 980px) {
        padding: 10px 17px 10px;
        border-radius: 6px;
    }
`;
const ApplyButton = styled.button`
    background-color: #601b6e;
    border-radius: 8px;
    padding: 13px 20px 13px 20px;
    margin-top: 10px;
    font-family: "product_sansregular";
    cursor: pointer;
    width: 100%;
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
    @media all and (max-width: 980px) {
        font-size: 15px;
    }
`;
const Apply = styled.div`
    font-size: 20px;
    cursor: pointer;
    color: #ffffff;
    @media all and (max-width: 360px) {
        font-size: 16px;
    }
`;

const RightContainer = styled.div`
    width: 50%;
    text-align: center;
    @media all and (max-width: 768px) {
        width: 100%;
    }
    @media all and (max-width: 768px) {
        margin-bottom: 30px;
    }
`;
const Heading = styled.h2`
    color: #601b6e;
    font-size: 45px;
    font-family: "product_sansbold";
    margin-bottom: 10px;
    @media all and (max-width: 1280px) {
        font-size: 40px;
    }
    @media all and (max-width: 980px) {
        font-size: 35px;
    }
    @media all and (max-width: 480px) {
        font-size: 30px;
    }
`;
const SubHeading = styled.h4`
    color: #601b6e;
    font-size: 34px;
    margin-bottom: 12%;
    font-family: "product_sansbold";
    @media all and (max-width: 1280px) {
        font-size: 30px;
        margin-bottom: 21%;
    }
    @media all and (max-width: 1080px) {
        margin-bottom: 23%;
    }
    @media all and (max-width: 980px) {
        font-size: 24px;
        margin-bottom: 47%;
    }
    @media all and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 10px;
    }
    @media all and (max-width: 360px) {
        font-size: 18px;
    }
`;
const ImgContainer = styled.div`
    width: 71%;
    margin: 0 auto;
    @media all and (max-width: 1380px) {
        width: 86%;
    }
    @media all and (max-width: 1280px) {
        width: 90%;
    }
    @media all and (max-width: 1080px) {
        width: 100%;
    }
    @media all and (max-width: 768px) {
        width: 85%;
    }
`;
const Img = styled.img`
    display: block;
    width: 100%;
`;
const Number = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 15px 17px 15px;
    border-radius: 9px;
    margin-bottom: 20px;
    color: #222;
    width: 100%;
    font-size: 16px;
`;
const NumberInput = styled.input`
    margin-left: 10px;
    color: #222;
    font-size: 16px;
`;
const Small = styled.span``;
const ErrorMessage = styled.p`
    color: red;
    position: absolute;
    right: 0;
    bottom: -10px;
    font-size: 12px;
    &.custom {
        bottom: -26px;
    }
`;
