import React from "react";
import styled from "styled-components";

export default function OverView() {
    return (
        <OverViewSection>
            <Wrapper className="wrapper">
                <OverViewContainer>
                    <LeftContainer>
                        <ImgContainer>
                            <Img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/the-femme.svg"
                                alt="logo"
                            />
                        </ImgContainer>
                        <Heading>EmpowerHer</Heading>
                        <Paragraph>
                            A transformative journey designed to empower,
                            educate, and integrate young women into the dynamic
                            ecosystem of Talrop. This meticulously crafted
                            program aims to unlock the potential of young women
                            in their final year of college, setting them on a
                            path to becoming tomorrow's leaders in technology
                            and beyond.
                        </Paragraph>
                    </LeftContainer>
                    <RightContainer>
                        <Container>
                            <Img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-02-2024/empowerher.svg"
                                alt="logo"
                            />
                        </Container>
                    </RightContainer>
                </OverViewContainer>
            </Wrapper>
        </OverViewSection>
    );
}

const OverViewSection = styled.section`
    background-color: #601b6e;
    padding: 70px 0;
    @media all and (max-width: 480px) {
        padding: 60px 0;
    }
`;
const Wrapper = styled.section``;
const OverViewContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: 768px) {
        display: block;
        text-align: center;
    }
`;
const LeftContainer = styled.div`
    width: 50%;
    margin: 10px 0;
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const ImgContainer = styled.div`
    width: 25%;
    margin-bottom: 10px;
    @media all and (max-width: 768px) {
        margin: 0 auto;
        margin-bottom: 4px;
    }
`;
const Img = styled.img`
    display: block;
    width: 100%;
`;
const Heading = styled.div`
    color: #e0adeb;
    font-size: 48px;
    margin-bottom: 30px;
    font-family: "product_sansbold";
    @media all and (max-width: 1280px) {
        font-size: 40px;
        margin-bottom: 20px;
    }
    @media all and (max-width: 980px) {
        font-size: 30px;
    }
`;
const Paragraph = styled.div`
    font-size: 19px;
    color: #ffffff;
    font-family: "product_sansregular";
    line-height: 27px;
    width: 90%;
    @media all and (max-width: 1380px) {
        width: 100%;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
        line-height: 21px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
`;
const RightContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: end;
    @media all and (max-width: 768px) {
        display: block;
        margin: 0 auto;
    }
`;
const Container = styled.div`
    width: 60%;
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 80%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
